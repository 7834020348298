@font-face {
  font-family: 'Hogfish';
  src: url(./fonts/hogfish.otf);
}
@import url('https://fonts.googleapis.com/css2?family=Alata&display=swap');

.App {
  text-align: center;
}

body {
  background-color: #232623;
}

.main-wrapper {
  width: 1200px;
  margin: auto;
}

.pixi-wrapper {
  height: 500px;
  min-width: 620px;
  max-width: 58vw;
  margin: auto;
  margin-top: 50px;
  border-radius: 20px;
  background: rgb(255, 206, 7);
  background: linear-gradient(180deg, rgba(255, 206, 7, 1) 0%, rgba(255, 171, 7, 1) 100%);
}

.pixi-wrapper {
  position: relative;
}

.pixi-img-wrapper {
  position: relative;
  display: flex;
  width: auto;
}

.pixi-img-wrapper img {
  width: 500px;
  height: 500px;
}

.head-img,
.back-img,
.face-img,
.ears-img,
.mouth-img,
.torso-img,
.eyes-img,
.front-img,
.body-img {
  position: absolute;
}

.back-img {
  z-index: 1;
}
.body-img {
  z-index: 2;
}
.face-img {
  z-index: 3;
}
.ears-img {
  z-index: 4;
}

.torso-img {
  z-index: 5;
}
.eyes-img {
  z-index: 6;
}
.mouth-img {
  z-index: 7;
}
.front-img {
  z-index: 8;
}

.head-img {
  z-index: 9;
}

.items-container {
  display: flex;
  flex-wrap: wrap;

  margin: auto;
  height: auto;

  margin-top: 40px;
  width: 1200px;
}

.item-wrapper {
  width: 120px;
  height: 120px;
  background-color: rgb(31, 31, 34);
  margin: 6px;
  border-radius: 6px;

  overflow: hidden;
  transition-duration: 0.3s;
}

.item-wrapper:hover {
  transform: scale(1.1);
  transition-duration: 0.3s;
  background-color: #ffcc00;
  border-radius: 0px;
}

.item-image {
  height: 80px;
}

.item-wrapper img {
  width: 80px;
  height: 80px;
}
.item-name {
  background-color: #141010;
  height: 40px;

  color: rgb(197, 197, 197);
}

.item-name h3 {
  margin: 0px;
  padding-top: 6px;
  font-size: 1rem;
}

.category-wrapper {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 0;
}

.category-button {
  width: 80px;
  height: 80px;
  border: none;
  border-radius: 200px;
  margin: 10px;
  color: #232623;
  background: rgb(255, 206, 7);
  background: linear-gradient(0deg, rgba(255, 206, 7, 1) 0%, rgba(255, 171, 7, 1) 100%);
  font-size: 1.3rem;
  font-family: 'hogfish', sans-serif;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  transition-duration: 0.3s;
  cursor: pointer;
}

.category-button:hover {
  transform: scale(1.2);
  transition-duration: 0.3s;
  background-color: #ffe57c;
}

<!-- DESCRIPTION STUFF------------------------------------------------------- .desc-wrapper {
  margin: 40px;
}

.pixi-desc-wrapper {
  font-family: 'Alata', sans-serif;
  color: #232623;
  text-align: right;
  padding: 40px;
}

.pixi-title {
  font-size: 3rem;
  text-transform: uppercase;
  margin-bottom: 5px;
  margin-top: -20px;
}

.item-lore {
  font-size: 1.8rem;
  text-transform: capitalize;
  margin-top: 0px;
}

.rarity-wrapper {
  margin: auto;
  display: flex;
  justify-content: flex-end;
  margin-top: 280px;
}

.rarity-text {
  color: #232623;
  text-transform: uppercase;
  width: 150px;
  font-size: 1.2rem;
}

.help-btn {
  width: 40px;
  border: none;
  background: none;
}

.help-btn img {
  width: 40px;
}

.help-btn:hover ~ .rarity-desc-box {
  visibility: visible;
  opacity: 1;
  transition: visibility 0.3s, opacity 0.3s;
}

.rarity-desc-box {
  width: 250px;
  height: 550px;
  background-color: #fff;
  position: absolute;
  text-align: left;
  padding: 20px;
  border-radius: 10px;
  left: 97%;
  top: 20%;
  z-index: 11;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s, opacity 0.3s;
}

.rarity-desc-box h3 {
  margin-bottom: 0px;
}

.rarity-desc-box p {
  margin-top: 2px;
}

.rarity-desc-box hr {
  border: none;
  height: 1px;
  background-color: #141010;
}

.rare-text {
  color: rgb(0, 154, 197);
}

.main-title {
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  font-family: 'Alata', sans-serif;
}

.main-title h1 {
  text-transform: uppercase;
  font-size: 2.5rem;
  margin-bottom: 0px;
  width: 100%;
  text-align: left;
}

.main-title p {
  color: #fff;
  font-size: 1.1rem;
  margin-top: 0px;
  width: 60%;
  text-align: left;
}

.tnb-link {
  color: #ffcc00;
  cursor: pointer;
}

.tnb-link a {
  text-decoration: none;
  color: #ffcc00;
}

@media screen and (min-width: 768px) {
  .mobile-warning {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .main-wrapper {
    display: none;
  }

  .mobile-warning {
    display: block;
    color: #fff;
  }
}
